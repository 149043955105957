.item-rarity-shop-card-bg {
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}

.size {
  &--l {
    width: 224px;
    height: 224px;
    min-height: 224px;
    min-width: 224px;
  }

  &--m {
    width: 168px;
    height: 168px;
    min-height: 168px;
    min-width: 168px;
  }
}
